.main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .content {
    /* padding: 2em; */
    background-color: #f3f4f6;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: 2em;
    height: calc(100% - 68px); /* Ajuste considerando a altura do Navbar */
  }
  
  