.cliente-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cliente-header {
  margin: 3px;
  text-align: center;
  padding-bottom: 25px;
  padding-top: 25px;
  font-size: 2em;
  color: #343a40;
  background-color: rgb(221, 221, 221);
  border-radius: 5px;
  border: 2px solid #b6b6b6; 
}

.cliente-actions {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn-create {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.btn-create:hover {
  background-color: #218838;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.input-search {
  padding: 10px;
  border-radius: 4px ;
  border: 1px solid #ced4da;
  font-size: 16px;
  width: 250px;
}

.btn-search {
  padding: 10px;
  background-color: #208cff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-search:hover {
  background-color: #0069d9;
}





