.table-container {
    margin-top: 20px;
    
    padding: 1em;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    
  }
  
  .table th, .table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    
  }
  
  .table th {
    background-color: #343a40;
    color: white;
  }
  
  .table tr:nth-child(even) {
    background-color: #f8f9fa;
  }
  
  .table tr:hover {
    background-color: #f1f1f1;
  }
  
  .btn-edit, .btn-delete {
    padding: 8px 12px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
  }
  
  .btn-edit {
    background-color: #007bff;
  }
  
  .btn-delete {
    background-color: #dc3545;
  }
  