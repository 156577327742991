.sidebar {
  width: 200px;
  background-color: #343a40;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-option {
  width: 80%;
  padding: 1em;
  text-align: center;
  cursor: pointer;
  color: white;
  border-bottom: 1px solid rgb(110, 110, 110);
}

.sidebar-option.active {
  background-color: #495057;
}

.sidebar-option:hover { 
  background-color: #576068;
}