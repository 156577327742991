.modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }

.modal-body {
    background-color: white;
    padding: 24px;
    border-radius: 14px;
    width: 60%;
    max-width: 600px;
    /* height: 60%; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    align-items: flex-start;
    
    display: flex;
    flex-direction: column;
}

.modal-body h2 {
    font-size: 32px;
}

.modal-overlay .modal-body .input-container {
    width: calc(100% - 24px);
}

.modal-body input {
  padding: 12px;
  border: 2px solid #ccc;
  color: #333;
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
}

.modal-body .btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

.modal-overlay .modal-body label {
    color: #242424;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 18px;
}

.btn-secondary, .btn-cancel {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.btn-secondary {
    background-color: #28a745;
    color: white;
}
  
.btn-secondary:hover {
    background-color: #218838;
}
  
.btn-cancel {
    background-color: #6c757d;
    color: white;
}
  
.btn-cancel:hover {
    background-color: #5a6268;
}

.modal-body select {
    padding: 12px;
    border: 2px solid #ccc;
    color: #333;
    font-size: 16px;
    border-radius: 8px;
    width: 105%;
    margin-bottom: 15px;
    background-color: white;
    cursor: pointer;
}

.modal-body select:focus {
    border-color: #1b1b1b; /* Cor do contorno ao focar */
    outline: none;
}

.modal-body option {
    padding: 10px;
}

.error-message {
    color: red;
    margin-top: 10px;
    /* background-color: #bebebe; */
  }

